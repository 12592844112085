<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-input
        v-model="listQuery.name"
        placeholder="请输入专业"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option
          v-for="(v, i) in colleges"
          :key="i"
          :label="v.name"
          :value="v.id"
        ></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.college.name }}
        </template>
      </el-table-column>
      <el-table-column label="专业" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.name }} </template>
      </el-table-column>
      <el-table-column label="初始年级" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.init_grade.name }} </template>
      </el-table-column>
      <el-table-column label="学年制度" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.study_year_num }} </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_on == 1" type="success">正常</el-tag>
          <el-tag v-else type="danger">已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.has_class_num == 0"
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '删除')"
            >删除</el-button
          >
          <!-- <el-button
            v-else
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '上架')"
            >上架</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="200px">
        <el-form-item label="请选择院系" prop="college_id">
          <el-select
            v-model="form.college_id"
            placeholder="请选择院系"
            clearable
            style="width: 250px"
            class="filter-item"
          >
            <el-option
              v-for="(v, i) in colleges"
              :key="i"
              :label="v.name"
              :value="v.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业" prop="name">
          <el-input type="text" style="width: 250px" v-model="form.name" clearable placeholder="请输入专业名称"/>
        </el-form-item>
        <!-- <el-form-item label="是否电竞专业" required>
          <el-switch
            v-model="form.is_e_sports"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
          >
          </el-switch>
        </el-form-item> -->
        <el-form-item label="初始年级" prop="init_grade_id">
          <el-select
            v-model="form.init_grade_id"
            placeholder="请选择初始年级"
            clearable
            style="width: 250px"
            class="filter-item"
          >
            <el-option
              v-for="(v, i) in gradeList"
              :key="i"
              :label="v.name"
              :value="v.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学年制度" prop="study_year_num">
          <el-input
            v-model="form.study_year_num"
            style="width: 250px"
            onkeyup="this.value=this.value.replace(/[^0-9]/g, '')"
            onafterpaste="this.value=this.value.replace(/[^0-9]/g, '')"
            placeholder="请输入学年制度"
          ></el-input>年制
          <!-- <el-input type="number" v-model="form.study_year_num" clearable /> -->
        </el-form-item>
        <!-- <el-form-item label="专业" prop="name">
          <el-input type="text" v-model="form.name" clearable placeholder="请输入专业"/>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  data() {
    return {
      list: null,
      total: null,
      listLoading: true,
      colleges: [],
      gradeList: [],
      listQuery: {
        page: 1,
        limit: 10,
        name: "",
        college_id: "",
        is_on: "",
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      form: {
        id: "",
        name: "",
        study_year_num:'',
        is_e_sports:'0',
        init_grade_id:'',
        college_id: "",
      },
      rules: {
        name: [{ required: true, message: "专业不能为空", trigger: "change" }],
        study_year_num: [{ required: true, message: "学年制度不能为空", trigger: "change" }],
        init_grade_id: [
          { required: true, message: "请选择初始年级", trigger: "change" },
        ],
        college_id: [
          { required: true, message: "请选择院系", trigger: "change" },
        ],
      },
      btnLoading: false,
    };
  },
  created() {
    this.getCollegeList();
    this.getList();
    this.getgradeList()
  },
  methods: {
    getCollegeList() {
      request({
        url: "/api/schoolend/school/collegeList",
        method: "get",
        params: {
          limit: 999899999999999,
          is_on: 1,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    getgradeList() {
      request({
        url: "/api/schoolend/school/gradeList",
        method: "get",
        params: {
          limit: 999899999999999,
          is_on: 1,
        },
      }).then((response) => {
        this.gradeList = response.data.data;
      });
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/school/majorList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        study_year_num:'',
        is_e_sports:'0',
        init_grade_id:'',
        college_id: "",
      };
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/school/majorStore",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.getList();
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该专业, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/schoolend/school/majorDel",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.el-input {
  width: 50%;
}
</style>
